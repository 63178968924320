import { stripNewLines } from '@/lib/utils';

import { JourneyModel } from '../types/models/journey';

export const mockJourneyInfos: JourneyModel['infos'] = {
  health: {
    band: 'Réouverture des frontières au tourisme à partir du 10 juin 2022',
    infos: [
      {
        title: 'Vaccins obligatoires',
        content: stripNewLines(`Pas de vaccin obligatoire, sauf contre la fièvre jaune pour les
                        voyageurs en provenance de pays d'Afrique subsaharienne et d'Amérique
                        latine où la maladie est présente.`)
      },
      {
        title: 'Vaccins conseillés',
        content: stripNewLines(`Comme toujours et partout, ile st important d'être protégé contre la
                        diphtérie, le tétanos, la poliomyélite, les hépatites A et B, la
                        coqueluche, rougeole surtout pour les enfant et, éventuellement, contre la
                        typhoïde (poisson cru).`)
      }
    ]
  },
  weather: {
    months: [
      { month: 'jan.', fill: '75%' },
      { month: 'fév.', fill: '25%' },
      { month: 'mar.', fill: '100%' },
      { month: 'avr.', fill: '100%' },
      { month: 'mai', fill: '50%' },
      { month: 'juin', fill: '50%' },
      { month: 'juil.', fill: '50%' },
      { month: 'août', fill: '0%' },
      { month: 'sep.', fill: '100%' },
      { month: 'oct.', fill: '100%' },
      { month: 'nov.', fill: '100%' },
      { month: 'déc.', fill: '100%' }
    ],
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ' +
      'imperdiet porttitor libero ut rutrum. Cras condimentum dolor sit amet faucibus ' +
      'euismod. Donec efficitur nec odio nec vehicula.'
  },
  flight: {
    flightDuration: '10h30',
    jetLag: '+8h',
    visa: 'Requis'
  },
  places: [
    {
      name: 'Name #1',
      image: '/uploads/placeholder_5109e12df7.svg',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam imperdiet porttitor libero ut rutrum. Cras condimentum dolor sit amet faucibus euismod. Donec efficitur nec odio nec vehicula'
    },
    {
      name: 'Name #2',
      image: '/uploads/placeholder_5109e12df7.svg',
      description:
        'Hello world Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam imperdiet porttitor libero ut rutrum. Cras condimentum dolor sit amet faucibus euismod. Donec efficitur nec odio nec vehicula'
    },
    {
      name: 'Name #3',
      image: '/uploads/placeholder_5109e12df7.svg',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam imperdiet porttitor libero ut rutrum. Cras condimentum dolor sit amet faucibus euismod. Donec efficitur nec odio nec vehicula'
    },
    {
      name: 'Name #4',
      image: '/uploads/placeholder_5109e12df7.svg',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam imperdiet porttitor libero ut rutrum. Cras condimentum dolor sit amet faucibus euismod. Donec efficitur nec odio nec vehicula'
    },
    {
      name: 'Name #5',
      image: '/uploads/placeholder_5109e12df7.svg',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam imperdiet porttitor libero ut rutrum. Cras condimentum dolor sit amet faucibus euismod. Donec efficitur nec odio nec vehicula'
    },
    {
      name: 'Name #6',
      image: '/uploads/placeholder_5109e12df7.svg',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam imperdiet porttitor libero ut rutrum. Cras condimentum dolor sit amet faucibus euismod. Donec efficitur nec odio nec vehicula'
    },
    {
      name: 'Name #7',
      image: '/uploads/placeholder_5109e12df7.svg',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam imperdiet porttitor libero ut rutrum. Cras condimentum dolor sit amet faucibus euismod. Donec efficitur nec odio nec vehicula'
    },
    {
      name: 'Name #8',
      image: '/uploads/placeholder_5109e12df7.svg',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam imperdiet porttitor libero ut rutrum. Cras condimentum dolor sit amet faucibus euismod. Donec efficitur nec odio nec vehicula'
    },
    {
      name: 'Name #9',
      image: '/uploads/placeholder_5109e12df7.svg',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam imperdiet porttitor libero ut rutrum. Cras condimentum dolor sit amet faucibus euismod. Donec efficitur nec odio nec vehicula'
    },
    {
      name: 'Name #10',
      image: '/uploads/placeholder_5109e12df7.svg',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam imperdiet porttitor libero ut rutrum. Cras condimentum dolor sit amet faucibus euismod. Donec efficitur nec odio nec vehicula'
    },
    {
      name: 'Name #11',
      image: '/uploads/placeholder_5109e12df7.svg',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam imperdiet porttitor libero ut rutrum. Cras condimentum dolor sit amet faucibus euismod. Donec efficitur nec odio nec vehicula'
    },
    {
      name: 'Name #12',
      image: '/uploads/placeholder_5109e12df7.svg',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam imperdiet porttitor libero ut rutrum. Cras condimentum dolor sit amet faucibus euismod. Donec efficitur nec odio nec vehicula'
    }
  ],
  encounters: [
    {
      imageUrl: '/uploads/placeholder_5109e12df7.svg',
      imageAlt: '',
      tag: 'Visioconférence',
      title: 'Voyages au Japon',
      description:
        'Passez librement entre 10h30 et 11h30, nous vous proposons plusieurs sessions mettant à l’honneur l’art de la cérémonie du thé. L’occasion d’en apprendre plus sur cette coutume sacralisée !',
      slug: ''
    },
    {
      imageUrl: '/uploads/placeholder_5109e12df7.svg',
      imageAlt: '',
      tag: 'Visioconférence',
      title: 'Voyages au Tibet',
      description:
        'Passez librement entre 10h30 et 11h30, nous vous proposons plusieurs sessions mettant à l’honneur l’art de la cérémonie du thé. L’occasion d’en apprendre plus sur cette coutume sacralisée !',
      slug: ''
    }
  ],
  articles: [
    {
      imageUrl: '/uploads/placeholder_5109e12df7.svg',
      imageAlt: '',
      title: 'Festival de masques à Tokyo',
      description:
        stripNewLines(`Porter le masque dans une joyeuse ambiance ? Aux curieux d'ailleurs
                          haut en couleur et d'animations majestueuses, aux passionnés de
                          costumes...`),
      slug: '',
      tag: ''
    },
    {
      imageUrl: '/uploads/placeholder_5109e12df7.svg',
      imageAlt: '',
      title: 'Japon arty',
      description: stripNewLines(`Dominique et Denis rêvaient d'un parcours artistique pour rendre
                          visite à leur ami sculpteur à Oita. D'Oita à Osaka, en passant par
                          Nagasaki...`),
      slug: '',
      tag: ''
    }
  ],
  catalog: [
    {
      imageUrl: '/uploads/placeholder_5109e12df7.svg',
      imageAlt: '',
      title: 'Voyages au Japon',
      description: 'Noto, Naoshima, Teshima, Inujima, les îles musées, Okinawa, et plus ...',
      label: 'Consulter le pdf en ligne',
      slug: '',
      tag: ''
    }
  ],
  otherArticles: [
    {
      imageUrl: '/uploads/placeholder_5109e12df7.svg',
      imageAlt: '',
      title: 'Notre engagement responsable',
      description:
        stripNewLines(`De l'art du bien voyager. Pour exalter la magie de la découverte du
                          monde, nous avons à coeur de donner du sens à nos voyages. Plus que
                          visiter un pays, cette sélection d'échappées teintées de vert, encore
                          plus...`),
      slug: '',
      tag: ''
    },
    {
      imageUrl: '/uploads/placeholder_5109e12df7.svg',
      imageAlt: '',
      title: 'Notre engagement très responsable',
      description:
        stripNewLines(`De l'art du bien voyager. Pour exalter la magie de la découverte du
                          monde, nous avons à coeur de donner du sens à nos voyages. Plus que
                          visiter un pays, cette sélection d'échappées teintées de vert, encore
                          plus...`),
      slug: '',
      tag: ''
    }
  ]
};

export const mockProductDataRatings = {
  count: 42,
  average: 4,
  ratings: [
    {
      author: 'Michael Bishop',
      date: new Date(),
      title: 'Superbe voyage',
      rating: 4,
      content: stripNewLines(`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus
                    mauris gravida lectus viverra donec ac pellentesque consectetur. Ac
                    facilisis et elementum vel convallis consectatur vitae.`),
      itemName: 'Merveilles du Grand Tokyo'
    },
    {
      author: 'Michael Bishop',
      date: new Date(),
      title: 'Superbe voyage',
      rating: 4,
      content: stripNewLines(`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus
                    mauris gravida lectus viverra donec ac pellentesque consectetur. Ac
                    facilisis et elementum vel convallis consectetur vitae.`),
      itemName: 'Merveilles du Grand Tokyo'
    },
    {
      author: 'Michael Bishop',
      date: new Date(),
      title: 'Superbe voyage',
      rating: 4,
      content: stripNewLines(`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus
                    mauris gravida lectus viverra donec ac pellentesque consectetur. Ac
                    facilisis et elementum vel convallis consectotur vitae.`),
      itemName: 'Merveilles du Grand Tokyo'
    },
    {
      author: 'Michael Bishop',
      date: new Date(),
      title: 'Superbe voyage',
      rating: 4,
      content: stripNewLines(`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus
                    mauris gravida lectus viverra donec ac pellentesque consectetur. Ac
                    facilisis et elementum vel convallis consectotur vitae.`),
      itemName: 'Merveilles du Grand Tokyo'
    },
    {
      author: 'Michael Bishop',
      date: new Date(),
      title: 'Superbe voyage',
      rating: 4,
      content: stripNewLines(`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus
                    mauris gravida lectus viverra donec ac pellentesque consectetur. Ac
                    facilisis et elementum vel convallis consectotur vitae.`),
      itemName: 'Merveilles du Grand Tokyo'
    },
    {
      author: 'Michael Bishop',
      date: new Date(),
      title: 'Superbe voyage',
      rating: 4,
      content: stripNewLines(`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus
                    mauris gravida lectus viverra donec ac pellentesque consectetur. Ac
                    facilisis et elementum vel convallis consectotur vitae.`),
      itemName: 'Merveilles du Grand Tokyo'
    }
  ]
};
